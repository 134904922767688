<template>
  <footer class="footer">
    <section>
      <div class="logocontainer">
        <img src="../assets/logo_transparent.svg" alt="Lendr logo" />
        <h2>Lendr</h2>
      </div>
      <div class="iconsContainer">
        <a
          href="https://discord.gg/tUyGf7JwQD"
          aria-label="discord logo, link to discord"
        >
          <Icon icon="mdi:discord" />
        </a>
        <a
          href="https://lendrfinance.medium.com/"
          target="_blank"
          aria-label="medium logo, link to medium"
        >
          <Icon icon="bx:bxl-medium-square" />
        </a>
        <a
          href="https://github.com/LendrFinance?tab=repositories"
          target="_blank"
          aria-label="github logo, link to github"
          ><Icon icon="akar-icons:github-fill"
        /></a>
      </div>
      <div class="iconsContainer">
        <a
          href="https://twitter.com/lendrfinance"
          target="_blank"
          aria-label="twitter logo, link to twitter"
        >
          <Icon icon="akar-icons:twitter-fill" />
        </a>
      </div>
    </section>
    <section class="linksWithHover">
      <h2>Products</h2>
      <a href="#">Mint (Coming soon)</a>
      <a href="#">Stake (Coming soon)</a>
      <a href="#">NFTs (Coming soon)</a>
    </section>
    <section class="linksWithHover">
      <h2>Learn</h2>
      <a href="https://docs.lendr.finance" target="_blank">Documentation</a>
      <a href="https://lendrfinance.medium.com" target="_blank">Medium</a>
      <a href="https://polygon.technology" target="_blank">Polygon</a>
    </section>
    <section class="linksWithHover">
      <h2>Join The Community</h2>
      <a href="https://discord.gg/tUyGf7JwQD" target="_blank">Join Discord</a>
    </section>
  </footer>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 332px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  border: 3px solid var(--secondary);
  border-width: 3px 0px 0 0px;
  display: flex;
  justify-content: space-evenly;
  padding: 60px 50px;
  flex-wrap: wrap;
  @media only screen and (max-width: 1200px) {
    position: relative;
    border: none;
    padding: 100px 0px;
    height: auto;
  }
  @media only screen and (max-width: 800px) {
    justify-content: flex-start;
    padding: 150px 5vw;
    gap: 40px;
  }
  .linksWithHover {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 210px;

    h2,
    a {
      color: var(--textColor);
      text-decoration: none;
      width: fit-content;
      margin-bottom: 5px;
    }
    a:after {
      content: "";
      display: block;
      width: 0px;
      border-bottom: 1px solid rgb(255, 255, 255);
      transition: 0.3s;
      padding-top: 3px;
    }
    a:hover:after {
      width: calc(10px + 100%);
    }
    h2 {
      font-size: 1.3em !important;
      opacity: 0.8;
      margin-top: 5px;
      margin-bottom: 30px;
      @media only screen and (max-width: 800px) {
        margin-bottom: 5px;
      }
    }
  }
  section:nth-child(2) {
    a {
      opacity: 0.3;
    }
    a:hover {
      cursor: auto;
    }
    a:hover:after {
      width: 0;
    }
  }
  section:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .logocontainer {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-bottom: 15px;
      margin-bottom: 5px;
      img {
        width: 30px;
      }
      h2 {
        color: var(--textColor);
        font-size: 1.3em;
        opacity: 1;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    .iconsContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      margin-bottom: 20px;
      svg {
        height: 40px;
        width: 40px;
        color: var(--textColor);
        transition: all 0.5s;
      }
      svg:nth-child(2) {
        height: 48px;
        width: 48px;
      }
      svg:hover {
        cursor: pointer;
        filter: drop-shadow(0px 0px 10px var(--secondary));
      }
    }
  }
}
</style>
